/**
 * This is a minimal config.
 *
 * If you need the full config, get it from here:
 * https://unpkg.com/browse/tailwindcss@latest/stubs/defaultConfig.stub.js
 */


const plugin = require('tailwindcss/plugin')

// We override lineCamp because of a bug in safari and firefox where lineClamping only works when all children are display:inline
const lineClamp = plugin(
    function ({ matchUtilities, addUtilities, theme, variants, e }) {
        const values = theme('lineClamp')
        matchUtilities(
            {
                'line-clamp': (value) => ({
                    '& *': {
                        'display': 'inline'
                    }
                }),
            },
            { values }
        )
    },
)


module.exports = {
    presets: [require('./tailwind-base.config')],
    content: [
        "./node_modules/flowbite/**/*.js",
        "./src/oscar_override/order/templatetags/order_status.py",
        "./src/oscar_override/shipping/models/methods.py",
        //
        // /*
        //  * Main templates directory of the project (BASE_DIR/templates).
        //  * Adjust the following line to match your project structure.
        //  */
        "src/**/templates/**/*.html",
        "!*.pdf.html", // exclude invoice since it's handled by weasyprint config
    ],
    theme: {
        screens: {
            // explicite all screen sizes instead of extending default one to avoir conflicts.
            // see https://stackoverflow.com/questions/59999022/why-screen-xl-and-screen-lg-gets-overridden-by-screen-md-in-tailwindcss
            // and https://tailwindcss.com/docs/screens#max-width-breakpoints
            'xs': '540px',
            'sm': '640px',
            'md': '768px',
            'lg': '1024px',
            'xl': '1280px',
            '2xl': '1536px',
        },
        extend: {
            listStyleType: {
                circle: "circle",
            },
            fontSize: {
                'xxs': ['0.625rem', '0.85rem'],
            },
            gridTemplateAreas: {
                'headerwide': [
                    'logo    countryselector searchbar   usermenu',
                    'btmenu  promotional     promotional promotional',
                    'modal   modal           modal       modal'
                ],

                'headerslim': [
                    'btmenu          logo            usermenu',
                    'modal           modal           modal',
                    'countryselector countryselector countryselector',
                    'searchbar       searchbar       searchbar',
                    'promotional     promotional     promotional'
                ]
            }
        },
    },
    plugins: [
        /**
         * '@tailwindcss/forms' is the forms plugin that provides a minimal styling
         * for forms. If you don't like it or have own styling for forms,
         * comment the line below to disable '@tailwindcss/forms'.
         */
        require("@tailwindcss/forms"),
        // require("@tailwindcss/line-clamp"), // now included by default in Tailwind CSS v3.3+
        require('flowbite/plugin'),
        require("@savvywombat/tailwindcss-grid-areas"),
        lineClamp
    ],
    variants: {
        gridTemplateAreas: ['responsive']
    }
};
